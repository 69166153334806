<template>
  <div id="no-map"></div>
</template>
<script>

export default {
  name: "MapDisabled",
  props: {
    zoom: {
      type: Number,
      default: 0,
    },
    center: {
      type: Array,
      default: ()=>([]),
    },
    url: {
      type: String,
      default: "",
    },
    attribution: {
      type: String,
      default: "",
    },
    marker: {
      type: Array,
      default: ()=>([]),
    }
  },
  components: {},
};
</script>
<style lang="scss" scoped>

</style>
